import React from "react";
import nodata from "../../assets/images/no-data-concept1.png";
const NoData = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <img src={nodata} alt="" />

        {/* <p className="text-gray-500 mt-2"> Sorry!! No Data Found</p> */}
      </div>
    </div>
  );
};

export default NoData;
