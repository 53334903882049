import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login/login";
import Home from "../pages/home/home";
import Varification from "../pages/Varification/varification";
import PersonalInfo from "../pages/personalInfo/PersonalInfo";
import BookingDetails from "../pages/bookingDetails/BookingDetails";
import ServiceDetails from "../pages/serviceDetails/serviceDetails";
import ButlerDetails from "../pages/butlerDetails/ButlerDetails";
import BookAppointment from "../pages/bookAppointment/BookAppointment";
import SessionBooked from "../pages/sessionBooked/SessionBooked";
import Review from "../pages/reviewPage/ReviewPage";
import CheckOutPage from "../pages/checkOutPage/CheckOutPage";
import MoreToDiscover from "../pages/moreToDiscover/MoreToDiscover";
import ErrorPage from "../Components/ErrorPage/errorPage";
import NoData from "../Components/Nodata/NoData";

export default function router() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<ErrorPage />} />
          <Route path="/404_error" element={<ErrorPage />} />
          <Route path="/no_data_found" element={<NoData />} />
          <Route path="/" element={<Home />} />
          <Route path="/service/:id/:cid" element={<ServiceDetails />} />
          <Route path="/more_to_discover" element={<MoreToDiscover />} />
          <Route path="/butler" element={<ButlerDetails />} />
          <Route path="/book_appointment" element={<BookAppointment />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verification" element={<Varification />} />
          <Route path="/personal_info" element={<PersonalInfo />} />
          <Route path="/review" element={<Review />} />
          <Route path="/checkout" element={<CheckOutPage />} />
          <Route path="/session_booked" element={<SessionBooked />} />
          <Route path="/booking_details" element={<BookingDetails />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
