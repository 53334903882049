import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import distance from "../../assets/images/distance.png";
import star from "../../assets/images/Vector.png";
import line from "../../assets/images/Line.png";
import p6 from "../../assets/images/UsersThree.png";
import { FiChevronLeft } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import clock from "../../assets/images/Time.png";
import calendar from "../../assets/images/Calendar.png";
import pending from "../../assets/images/pending.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import toast from "react-hot-toast";
import { bookingDetails } from "../../Services/ApiService";
import { convertTo12HourFormat } from "../../Components/TimeContext/TimeContext";
import { isIOS } from "react-device-detect";

export default function BookingDetails() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const Business = JSON.parse(localStorage.getItem("businessData"));
  const token = localStorage.getItem("token");
  const booking_id = localStorage.getItem("bookingId");

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("completeBooking", true);

    if (!token) {
      navigate("/login");
    } else {
      fetchbookingDetails();
    }
  }, []);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const options = { month: "long", day: "numeric" };
    const day = date.getDate();

    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return date.toLocaleDateString("en-US", options) + getOrdinalSuffix(day);
  };

  const fetchbookingDetails = () => {
    let params = { booking_id: Number(booking_id) };
    bookingDetails(params)
      .then((res) => {
        const status = res.data?.status;
        const data = res.data?.data;
        if (status == 1) {
          setData(data);
          setLoader(false);
        } else {
          toast.error(res.data?.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        const errs = err.response?.data;
        toast.error(errs?.message || errs?.Message || "An error occurred.");
      });
  };

  const formatDuration = (duration) => {
    if (!duration || typeof duration !== "string") {
      return "N/A";
    }
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    let result = [];

    if (hours > 0) {
      result.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    }
    result.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    return result.join(" ");
  };

  const handleClick = () => {
    localStorage.removeItem("butler");
    localStorage.removeItem("butlerId");
    localStorage.removeItem("categoryId");
    localStorage.removeItem("date");
    localStorage.removeItem("mainService");
    localStorage.removeItem("moreDiscover");
    localStorage.removeItem("notes");
    localStorage.removeItem("selectedAddon");
    localStorage.removeItem("selectedService");
    localStorage.removeItem("selectedTime");
    localStorage.removeItem("serviceDetails");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("timeSlot");
    navigate(`/?id=${Business.id}`);
  };

  return (
    <div>
      {loader ? (
        <SkeletonTheme
          highlightColor="#ffffff30"
          borderRadius="0.5rem"
          duration={1.5}
        >
          <div className="py-4 container mx-auto bg-white z-10">
            <Skeleton height={50} />
          </div>
          <div className="container mx-auto">
            <Skeleton height={100} />
            <Skeleton height={100} className="mt-5" />
            <Skeleton height={200} className="mt-5" />
            <Skeleton height={200} className="mt-4" />
            <Skeleton height={50} className="mt-5" />
            <Skeleton height={200} className="mt-5" />
            <Skeleton height={50} className="mt-5" />
          </div>
        </SkeletonTheme>
      ) : data && data.length === 0 ? (
        <div className="container mx-auto">
          <p className="text-lg mt-3 text-[#8c8dab] font-medium">
            No Data Found
          </p>
        </div>
      ) : (
        <div className={`mb-5`}>
          <div className=" border-b pb-4 sm:w-[30rem] sm:mx-auto ">
            <div className="sticky top-0 z-10 bg-white container mx-auto ">
              <div className="px-4 py-3 bg-[#E7E4F7] rounded-2xl flex justify-between items-center ">
                <div className="flex items-center gap-x-2 sm:w-1/2">
                  <img src={logo} alt="Beauty Butler" className="w-12 h-12" />
                  <div>
                    <p className="sm:text-sm text-xs font-normal heading-font">
                      Beauty Butler
                    </p>
                    <p className="sm:text-xs text-[10px] text-[#667085] font-normal">
                      Beauty treatments direct to your doorstep
                    </p>
                  </div>
                </div>
                <a
                  className="bg-[#8877D8] text-white rounded-lg py-2 sm:px-4 sm:w-auto w-44 font-medium text-xs text-center ms-2"
                  href={`${isIOS
                    ? "https://apps.apple.com/app/id6608976314"
                    : "https://play.google.com/store/apps/details?id=com.beautybutler.use"
                    }`}
                  target="_blank"
                >
                  Download App
                </a>
              </div>
            </div>
          </div>
          <div className="mt-4 container mx-auto ">
            <div className="rounded-2xl flex justify-between items-center gap-x-2 mb-2">
              <div
                className="flex items-center justify-center cursor-pointer rounded-full w-7 h-7 bg-gray-100"
                onClick={() => handleClick()}
              >
                <FiChevronLeft className="w-4" />
              </div>
              <p className="sm:text-xl text-lg text-[#1D2939] font-medium heading-font">
                Booking Details
              </p>
              <p></p>
            </div>

            <div className="flex items-center gap-x-3.5 mb-4 py-4">
              <img
                src={
                  data &&
                  data?.booking_services[0]?.booked_service_images[0]
                    ?.service_image
                }
                className="h-24 w-24 object-cover rounded-xl"
                alt=""
              />
              <div className="sm:text-sm text-xs font-normal">
                <p className="text-[#8877D8] pb-0.5 heading-font sm:text-sm text-xs">
                  {data && data?.booking_business?.business_name}
                </p>
                <p className="text-gray-800 pb-0.5 sm:text-sm text-xs">
                  {data && data?.booking_services[0]?.service_name}
                </p>
                <div className="flex items-center mt-0.5">
                  <div className="flex items-center gap-x-1.5">
                    <img src={calendar} height={18} width={18} alt="" />
                    <p className="text-gray-800 sm:text-sm text-xs">
                      {data?.booking_date
                        ? formatDate(data.booking_date)
                        : "N/A"}
                    </p>
                  </div>
                  <img src={line} className="h-3 sm:px-3 px-1" alt="" />
                  <div className="flex items-center gap-x-1.5  ">
                    <img src={clock} height={18} width={18} alt="" />
                    <p className="text-gray-800 sm:text-sm text-xs">
                      <p className="text-gray-800 sm:text-sm text-xs">
                        {data?.booking_time
                          ? convertTo12HourFormat(data.booking_time)
                          : "N/A"}
                      </p>
                    </p>
                  </div>
                </div>
                <div className="flex items-center sm:gap-x-2 gap-x-0.5 mt-1">
                  <p className="sm:text-sm text-xs">
                    Booking ID: {data && data.id}
                  </p>
                  <div className="bg-gray-100 rounded-full flex items-center justify-center sm:gap-x-2 gap-x-0.5 py-1 w-20 sm:w-24">
                    <img src={pending} className="w-3 h-3" alt="" />
                    <p className="sm:text-sm text-xs">
                      {data && data.booking_status}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4">
              <div className="py-4">
                <p className="font-medium pb-3 heading-font sm:text-base text-sm">
                  Butler
                </p>
                <div className="rounded-2xl p-3 border border-gray-200">
                  <div className="flex items-center gap-x-4 ">
                    <img
                      src={data?.butler_details?.profile_image || p6}
                      className="w-10 h-10 rounded-full"
                      alt=""
                    />
                    <div>
                      <p className="font-medium text-gray-800 sm:text-base sm:text-sm text-xs">
                        {data?.butler_details?.fullname || "Any Butler"}
                      </p>
                      <p className="text-gray-500 sm:text-sm text-xs">
                        {data?.butler_details?.job_title}
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-400 sm:text-xs text-[10px] pt-1.5">
                    Download our mobile app to see your appointment status and
                    get instant updates on your booking status and chat with
                    your butler.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="my-4">
                <p className="text-gray-800 font-medium pt-3 heading-font sm:text-base text-sm">
                  OverView
                </p>
                <div className="rounded-2xl border border-gray-200 px-3 pb-3 mt-3">
                  {data?.booking_services.length > 0 &&
                    data?.booking_services.map((item, index) => (
                      <div
                        className="flex justify-between items-center gap-x-2 border-b py-4"
                        key={index}
                      >
                        <div className="sm:text-sm text-xs">
                          <p className="pb-1 text-gray-800">
                            {item.service_name}
                          </p>
                          <p className="text-gray-500">
                            {formatDuration(item.duration)}
                          </p>
                        </div>
                        <p className="text-gray-500 sm:text-sm text-xs">
                          ₦{item.service_amount}
                        </p>
                      </div>
                    ))}

                  <div className="flex items-center justify-between gap-x-2 font-medium my-3 sm:text-base text-sm">
                    <p>Total</p>
                    <p>₦{Number(data?.final_amount).toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="my-4">
                <p className="text-gray-800 font-medium pt-3 heading-font sm:text-base text-sm">
                  Contact Information
                </p>
                <div className="rounded-2xl border text-gray-800 px-3 mt-3">
                  <div className="flex justify-between items-center gap-x-2 border-b py-4">
                    <div className="sm:text-sm text-xs ">
                      <p className="pb-1 ">Full Name</p>
                    </div>
                    <p className=" sm:text-sm text-xs">{userData.fullname}</p>
                  </div>
                  <div className="flex justify-between items-center gap-x-2 border-b py-4">
                    <div className="sm:text-sm text-xs ">
                      <p className="pb-1 ">Email Address</p>
                    </div>
                    <p className="sm:text-sm text-xs">{userData.email_id}</p>
                  </div>
                  <div className="flex justify-between items-center gap-x-2 py-4">
                    <div className="sm:text-sm text-xs ">
                      <p className="pb-1">Phone Number</p>
                    </div>
                    <p className="sm:text-sm text-xs">
                      {userData.country_code} {userData.phone_number}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div>
                <p className="text-gray-800 font-medium pt-3 heading-font sm:text-base text-sm">
                  Address
                </p>
                <div className="rounded-2xl border border-gray-200 px-3 mt-3">
                  <div className="flex justify-between items-center gap-x-2 py-4">
                    <div className="flex items-center gap-x-1 w-full">
                      <img src={distance} alt="" className="w-6" />
                      <p className="text-gray-800 sm:text-sm text-xs w-full ">
                        {data?.address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div>
                  <p className="sm:text-base text-sm font-medium text-gray-800 heading-font pt-3">
                    Manage booking
                  </p>
                  <div className="flex items-center mt-3 gap-x-3.5 sm:text-sm text-xs">
                    <img src={star} alt="" width={14} />
                    <p>Manage your appointment </p>
                  </div>
                  <div className="flex items-center mt-1 gap-x-3.5 sm:text-sm text-xs">
                    <img src={star} alt="" width={14} />
                    <p>Chat with your butler </p>
                  </div>
                  <div className="flex items-center mt-1 gap-x-3.5 sm:text-sm text-xs">
                    <img src={star} alt="" width={14} />
                    <p>View your appointment status</p>
                  </div>
                </div>
                <a
                  href={`${isIOS
                    ? "https://apps.apple.com/app/id6608976314"
                    : "https://play.google.com/store/apps/details?id=com.beautybutler.use"
                    }`}
                  target="_blank"
                >
                  <div className="bg-[#8877D8] text-white rounded-2xl py-4 w-full font-medium sm:text-lg mt-3 text-center">
                    Download App
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
