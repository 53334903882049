import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getTimeSlot } from "../../Services/ApiService";
import toast from "react-hot-toast";
import {
  useTime,
  convertTo12HourFormat,
} from "../../Components/TimeContext/TimeContext";
import logo from "../../assets/images/logo.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { isIOS } from "react-device-detect";

export default function BookAppointment() {
  const navigate = useNavigate();
  const { setTime } = useTime();

  const today = new Date();
  const date1 = localStorage.getItem("date");
  const serviceId = localStorage.getItem("serviceId");
  const butler = JSON.parse(localStorage.getItem("butler"));
  const butlerId = JSON.parse(localStorage.getItem("butlerId"));
  const data = JSON.parse(localStorage.getItem("businessData"));

  const [isLoading, setIsLoading] = useState(false);
  const [multiSelectDiv, setMultiSelectedDiv] = useState(null);

  const [timeSlot, setTimeSlot] = useState({ data: "", status: "" });
  const [date, setDate] = useState(date1 ? new Date(date1) : today);

  useEffect(() => {
    const storedDate = new Date(localStorage.getItem("date")).toDateString();
    const selectedDate = date.toDateString();

    const storedTime = JSON.parse(localStorage.getItem("selectedTime"));
    if (storedDate === selectedDate && storedTime) {
      setMultiSelectedDiv(storedTime.id);
    } else {
      setMultiSelectedDiv(null);
    }
  }, [date]);

  useEffect(() => {
    if (butlerId && serviceId && data) {
      handleTimeSlot();
    } else {
      navigate("/");
    }
  }, [date]);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const handelNextDate = (selectedDate) => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);

    const options = { weekday: "short", day: "2-digit", month: "long" };
    const formattedDateParts = new Intl.DateTimeFormat(
      "en-US",
      options
    ).formatToParts(nextDate);
    const formattedDate = `${formattedDateParts[0].value}, ${formattedDateParts[4].value} ${formattedDateParts[2].value}`;

    return formattedDate;
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    localStorage.setItem("dateChange", selectedDate);
  };

  const handleMultiDivClick = (index, value) => {
    setMultiSelectedDiv(index);
    setTime(value);
    const obj = { id: index, time: value };
    localStorage.setItem("selectedTime", JSON.stringify(obj));
  };

  const setNextDate = () => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);

    setDate(nextDate);
    handleDateChange(nextDate);
  };

  const handleLogin = () => {
    const token = localStorage.getItem("token");

    if (token) {
      localStorage.setItem("date", moment(date).format("YYYY-MM-DD"));
      navigate("/review");
    } else if (date && multiSelectDiv !== null) {
      localStorage.setItem("date", moment(date).format("YYYY-MM-DD"));
      navigate("/login");
    }
  };

  const handleTimeSlot = () => {
    setIsLoading(true);

    let params = {};
    if (butlerId.id == 0) {
      params = {
        date: moment(date).format("YYYY-MM-DD"),
        business_id: data.id,
        service_id: serviceId,
      };
    } else {
      params = {
        date: moment(date).format("YYYY-MM-DD"),
        business_id: data.id,
        butler_id: butlerId.id,
        service_id: serviceId,
      };
    }

    getTimeSlot(params)
      .then((res) => {
        setIsLoading(false);
        const status = res.data.status;
        const data = res.data.data;

        if (status === 1) {
          setTimeSlot({ data: data, status: status });
          const obj = { data: data, status: status };
          localStorage.setItem("timeSlot", JSON.stringify(obj));
        } else if (status === 3) {
          const nextDateData = handelNextDate(date);
          setTimeSlot({ data: nextDateData, status: status });
          localStorage.setItem(
            "timeSlot",
            JSON.stringify({ data: nextDateData, status: status })
          );
        } else {
          setTimeSlot({ data: "", status: status });
          localStorage.setItem(
            "timeSlot",
            JSON.stringify({ data: "", status: status })
          );
          toast.error(data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        const errs = err.response?.data;
        const status = err.response?.status;

        if (status === 404) {
          const nextDateData = { date: handelNextDate(date), butler: butler };
          setTimeSlot({ data: nextDateData, status: status });

          localStorage.setItem(
            "timeSlot",
            JSON.stringify({ data: nextDateData, status: status })
          );
        } else {
          toast.error(errs?.Message || "An error occurred.");
        }
      });
  };

  const handleBack = () => {
    localStorage.removeItem("timeSlot");
    localStorage.removeItem("selectedTime");
    localStorage.removeItem("date");
    navigate(`/butler`);
  };

  return (
    <div>
      <div className="sticky top-0 z-10 bg-white ">
        <div className="border-b pb-4 sm:w-[30rem] sm:mx-auto">
          <div className="container mx-auto ">
            <div className="px-4 py-3 bg-[#E7E4F7] rounded-2xl flex justify-between items-center">
              <div className="flex items-center gap-x-2 sm:w-1/2">
                <img src={logo} alt="Beauty Butler" className="w-12 h-12" />
                <div>
                  <p className="sm:text-sm text-xs font-normal heading-font">
                    Beauty Butler
                  </p>
                  <p className="sm:text-xs text-[10px] text-[#667085] font-normal">
                    Beauty treatments direct to your doorstep
                  </p>
                </div>
              </div>
              <a
                className="bg-[#8877D8] text-white rounded-lg py-2 sm:px-4 sm:w-auto w-44 font-medium text-xs text-center ms-2"
                href={`${isIOS
                  ? "https://apps.apple.com/app/id6608976314"
                  : "https://play.google.com/store/apps/details?id=com.beautybutler.use"
                  }`}
                target="_blank"
              >
                Download App
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 container mx-auto ">
        <div className="rounded-2xl flex justify-between items-center gap-x-2 mb-6">
          <div
            className="flex items-center justify-center cursor-pointer rounded-full w-7 h-7 bg-gray-100"
            onClick={handleBack}
          >
            <FiChevronLeft className="w-4" />
          </div>
          <p className="sm:text-xl text-lg text-[#1D2939] font-medium heading-font">
            Select Date and Time
          </p>
          <p></p>
        </div>
        <p className="heading-font sm:text-xl text-lg font-medium pb-3 mt-6 ">
          Choose Date
        </p>
        <div className="w-full px-5 sm:px-0 mx-auto justify-center items-center mb-5 lg:mb-2 datecenter">
          <DatePicker
            className="w-full"
            dateFormat="yyyy-MM-dd"
            selected={date || date1}
            onChange={(selectedDate) => {
              handleDateChange(selectedDate);
            }}
            disabledKeyboardNavigation
            preventOpenOnFocus
            minDate={new Date()}
            inline
          />
        </div>
        <div className="mt-6 mb-32">
          {timeSlot.status == 1 ? (
            timeSlot.data.length !== 0 ? (
              <div>
                <p className="sm:text-xl text-lg text-[#1D2939] font-medium pb-3 heading-font">
                  Choose Time
                </p>

                <div className="grid grid-cols-12 gap-4 cursor-pointer">
                  {timeSlot.data.map((item, index) =>
                    isLoading ? (
                      <div className={`col-span-6 `} key={index}>
                        <Skeleton className="h-12 w-full rounded-2xl" />
                      </div>
                    ) : (
                      <div
                        className={`col-span-6 rounded-2xl border border-[#8877D8] text-center py-4 ${multiSelectDiv == index && "bg-[#8877D8]"
                          }`}
                        key={index}
                        value={item}
                        onClick={() => handleMultiDivClick(index, item)}
                      >
                        <p
                          className={`sm:text-lg text-[#8877D8] font-medium ${multiSelectDiv == index && "text-white "
                            }`}
                        >
                          {convertTo12HourFormat(item)}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full mt-16 text-center">
                <div className="flex justify-center ">
                  {isLoading ? (
                    <div className="w-16">
                      <Skeleton className="rounded-xl h-[60px] w-full" />
                    </div>
                  ) : (
                    <img
                      src={data.business_image}
                      alt=""
                      className="rounded-xl h-16 w-16 object-cover"
                    />
                  )}
                </div>
                <p className="pt-4 sm:text-lg text-[#1D2939] font-medium">
                  {butlerId.id > 0 ? butlerId?.fullname : `All butlers are`}
                  {""} Booked Today
                </p>
                <p className="text-[#667085] font-light sm:text-sm text-xs">
                  But you can book for {timeSlot.data}
                </p>

                <button
                  className="bg-[#8877D8] text-white sm:text-base text-sm font-medium py-3.5 px-6 rounded-xl mt-6"
                  onClick={setNextDate}
                >
                  Go to {handelNextDate(date)}
                </button>
                <br />
                <button
                  className="border-[1.5px] border-[#8877D8] text-[#8877D8] sm:text-base text-sm font-medium py-3.5 px-6 rounded-xl  mt-3"
                  onClick={handleBack}
                >
                  Try another professional
                </button>
              </div>
            )
          ) : (
            <>
              {timeSlot.status == 404 && (
                <div className="w-full mt-16 text-center">
                  <div className="flex justify-center ">
                    {isLoading ? (
                      <div className="w-16">
                        <Skeleton className="rounded-xl h-[60px] w-full" />
                      </div>
                    ) : butlerId.id != 0 ? (
                      <img
                        src={butlerId?.profile_image}
                        alt=""
                        className="rounded-xl h-16 w-16 object-cover"
                      />
                    ) : (
                      <img
                        src={data.business_image}
                        alt=""
                        className="rounded-xl h-16 w-16 object-cover"
                      />
                    )}
                  </div>
                  <p className="pt-4 sm:text-lg text-[#1D2939] font-medium">
                    Salon close Today
                  </p>
                  <p className="text-[#667085] font-light sm:text-sm text-xs">
                    But you can book for {timeSlot.data?.date}
                  </p>

                  <button
                    className="bg-[#8877D8] text-white sm:text-base text-sm font-medium py-3.5 px-6 rounded-xl mt-6"
                    onClick={setNextDate}
                  >
                    Go to {timeSlot.data?.date}
                  </button>
                  <br />
                  <button
                    className="border-[1.5px] border-[#8877D8] text-[#8877D8] sm:text-base text-sm font-medium py-3.5 px-6 rounded-xl  mt-3"
                    onClick={handleBack}
                  >
                    Try another professional
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="py-4 border-t fixed inset-x-0 bottom-0 z-10 bg-white border-t sm:w-[30rem] sm:mx-auto">
          <div className="container mx-auto ">
            <button
              className={`bg-[#8877D8] rounded-2xl py-4 w-full font-medium sm:text-lg ${date && multiSelectDiv !== null
                ? "bg-[#8877D8] text-white"
                : "bg-gray-300 text-gray-400 cursor-not-allowed"
                }`}
              onClick={() => handleLogin()}
              disabled={!date && multiSelectDiv === null}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
