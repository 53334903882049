import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo1 from "../../assets/images/Logo1.png";
import welcome from "../../assets/images/Welcome.png";
import star from "../../assets/images/Vector.png";
import { addBooking } from "../../Services/ApiService";
import {
  convertTo12HourFormat,
} from "../../Components/TimeContext/TimeContext";
import toast from "react-hot-toast";
import { isIOS } from "react-device-detect";

export default function SessionBooked() {
  const navigate = useNavigate();
  const [isCompleting, setIsCompleting] = useState(true);
  const [data, setData] = useState({});

  const query = new URLSearchParams(useLocation().search);
  const reference = query.get("reference");

  const storedDate = localStorage.getItem("date");

  const businessData = JSON.parse(localStorage.getItem("businessData"));
  const note = localStorage.getItem("notes");
  const service_id = localStorage.getItem("serviceId");
  const butlerId = JSON.parse(localStorage.getItem("butlerId"));
  const timeDetails = JSON.parse(localStorage.getItem("selectedTime"));
  const service = JSON.parse(localStorage.getItem("serviceDetails"));
  const selectedAddon = JSON.parse(localStorage.getItem("selectedAddon"));
  const address = localStorage.getItem("address");
  const completeBooking = localStorage.getItem("completeBooking");
  let serviceIds = [];

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (completeBooking) {
      navigate(`/?id=${businessData.id}`);
    }

    if (selectedAddon) {
      serviceIds = selectedAddon.map((service) => service.id);
    }

    if (!token) {
      navigate("/login");
    }

    if (isCompleting) {
      confirmBooking();
    }
  }, []);

  const confirmBooking = () => {
    let params;
    if (butlerId.id !== 0) {
      params = {
        butler_id: butlerId.id,
        business_id: businessData.id,
        booking_date: storedDate,
        booking_time: timeDetails.time,
        notes: note,
        payment_method: "Pay Online",
        service_id: Number(service_id),
        service_amount: service.mainPrice,
        addon_service_amount: service.addonPrice,
        actual_amount: service.totalPrice,
        discount_amount: 0,
        final_amount: service.totalPrice,
        addon_service_id: serviceIds,
        reference_id: reference,
        address: address,
      };
    } else {
      params = {
        business_id: businessData.id,
        booking_date: storedDate,
        booking_time: timeDetails.time,
        notes: note,
        payment_method: "Pay Online",
        service_id: Number(service_id),
        service_amount: service.mainPrice,
        addon_service_amount: service.addonPrice,
        actual_amount: service.totalPrice,
        discount_amount: 0,
        final_amount: service.totalPrice,
        addon_service_id: serviceIds,
        reference_id: reference,
        address: address,
      };
    }

    addBooking(params)
      .then((res) => {
        const status = res.data.status;
        const data1 = res.data.data;
        if (status == 1) {
          setData(data1);
          setIsCompleting(false);
          localStorage.setItem("bookingId", data1.id);
          toast.success(res.data.message);
        } else {
          setIsCompleting(true);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        const errs = err.response?.data;
        const status = err.response?.status;

        if (status == 409) {
          toast.error(errs?.message);
          navigate("/checkout");
        } else {
          setIsCompleting(false);
          toast.error(errs?.Message || "An error occurred.");
          navigate("/checkout");
        }
      });
  };

  const formatDuration = (duration) => {
    if (!duration || typeof duration !== "string") {
      return "N/A";
    }
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    let result = [];

    if (hours > 0) {
      result.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    }
    result.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    return result.join(" ");
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);

    return formattedDate.replace(day, `${day}${ordinalSuffix}`);
  }

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return (
    <>
      {isCompleting ? (
        <div className="min-h-screen flex items-center justify-center">
          <div>
            <img src={logo1} alt="" className="h-14 mx-auto" />
            <p className="text-[#4F4F4F] font-medium pt-6 sm:text-base text-sm">
              Finishing order...
            </p>
          </div>
        </div>
      ) : (
        <div className="container bg-[#8877D8]  mx-auto">
          <div className="min-h-screen px-5 py-10">
            <img
              src={welcome}
              alt="Welcome"
              className="sm:-mb-36 -mb-24 relative mx-auto px-20"
            />
            <div className="text-center py-8 mb-5">
              <div className="sm:pt-32 pt-20 pb-5 bg-white rounded-t-2xl rounded-bl-[30px] rounded-ee-[30px] px-7">
                <p className="text-[#8877D8] xl:text-[28px] text-2xl  font-medium heading-font">
                  Session Booked
                </p>
                <p className="pt-3 sm:text-sm text-xs text-gray-600 font-normal">
                  Your{" "}
                  {data.booking_services && data.booking_services.length > 0
                    ? data.booking_services[0].service_name
                    : ""}{" "}
                  with
                  <span className="font-semibold">
                    {" "}
                    {data.booking_business?.business_name}
                  </span>{" "}
                  is confirmed!
                </p>
              </div>
              <div className="border border-2 border-dashed border-gray-300 mx-10 bg-white "></div>
              <div className="py-5 bg-white rounded-b-2xl rounded-tr-[30px] rounded-tl-[30px] px-7 ">
                <div className="grid grid-cols-12 gap-x-2 text-start">
                  <div className="col-span-6">
                    <p className="text-gray-400 sm:text-xs text-[10px]">
                      Amount
                    </p>
                    <p className="text-[#1D2939] sm:text-sm text-xs">
                      ₦{Number(data.final_amount).toLocaleString()}
                    </p>
                  </div>
                  <div className="col-span-6">
                    <p className="text-gray-400 sm:text-xs text-[10px]">
                      Expected Duration
                    </p>
                    <p className="text-[#1D2939] sm:text-sm text-xs">
                      {data.duration && formatDuration(data.duration)}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-x-2 text-start sm:mt-10 mt-5 sm:mb-4">
                  <div className="col-span-6">
                    <p className="text-gray-400 sm:text-xs text-[10px]">Date</p>
                    <p className="text-[#1D2939] sm:text-sm text-xs">
                      {formatDate(storedDate)}
                    </p>
                  </div>
                  <div className="col-span-6">
                    <p className="text-gray-400 sm:text-xs text-[10px]">Time</p>
                    <p className="text-[#1D2939] sm:text-sm text-xs">
                      {data.booking_time
                        ? convertTo12HourFormat(data.booking_time)
                        : ""}
                    </p>
                  </div>
                </div>

                <p
                  className="text-center text-[#8877D8] underline sm:text-sm text-xs pt-5 font-medium cursor-pointer"
                  onClick={() => navigate("/booking_details")}
                >
                  View Booking Details
                </p>
              </div>
            </div>

            <div className="bg-white rounded-2xl px-7 py-6">
              <div className="text-center mb-3">
                <p className="font-medium text-[#1D2939] sm:text-xl text-lg heading-font">
                  Manage your appointment
                </p>
              </div>

              <div className="flex flex-col items-start gap-y-2 text-sm">
                <div className="flex items-center">
                  <img src={star} alt="Star" width={14} />
                  <p className="ml-3 text-gray-700 sm:text-base text-sm">
                    Manage your appointment
                  </p>
                </div>
                <div className="flex items-center">
                  <img src={star} alt="Star" width={14} />
                  <p className="ml-3 text-gray-700 sm:text-base text-sm">
                    Chat with your butler
                  </p>
                </div>
                <div className="flex items-center">
                  <img src={star} alt="Star" width={14} />
                  <p className="ml-3 text-gray-700 sm:text-base text-sm">
                    View your appointment status
                  </p>
                </div>
              </div>

              <a
                href={`${isIOS
                  ? "https://apps.apple.com/app/id6608976314"
                  : "https://play.google.com/store/apps/details?id=com.beautybutler.use"
                  }`}
                target="_blank"
              >
                <p className="bg-[#8877D8] text-white rounded-2xl py-4 w-full font-medium sm:text-lg mt-5 text-center">
                  Download App
                </p>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
