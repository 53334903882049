import { createContext, useState, useContext } from "react";

const TimeContext = createContext();

export const convertTo12HourFormat = (time24) => {
  const [hours, minutes, seconds] = time24.split(":");
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  // formattedTime = formattedTime.replace("AM", "am").replace("PM", "pm");

  // return formattedTime;
};

export const TimeProvider = ({ children }) => {
  const [selectedTime, setSelectedTime] = useState(null);
  const [formattedTime, setFormattedTime] = useState(null);

  const handleSetTime = (time) => {
    setSelectedTime(time);
    const formatted = convertTo12HourFormat(time);
    setFormattedTime(formatted);
  };

  return (
    <TimeContext.Provider
      value={{
        selectedTime,
        formattedTime,
        setTime: handleSetTime,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};

export const useTime = () => useContext(TimeContext);
