import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import distance from "../../assets/images/distance.png";
import distance1 from "../../assets/images/distance_gray.png";
import line from "../../assets/images/Line.png";
import Dialog from "../../Components/Dialog/Dialog";
import { IoClose } from "react-icons/io5";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from "react-select";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getBussiness } from "../../Services/ApiService";
import toast from "react-hot-toast";
import Progress from "react-progressbar";
import { isIOS } from "react-device-detect";

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const businessId = query.get("id");

  const tabFlag = localStorage.getItem("tabFlag");
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState({});
  const [singleService, setSingleService] = useState({});
  const [checked, setChecked] = useState([]);
  const [address, setAddress] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const [currentAddress, setCurrentAddress] = useState();
  const [img, setImg] = useState();
  const limit = 150;
  const [readFlag, setReadFlag] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("butler");
    localStorage.removeItem("butlerId");
    localStorage.removeItem("categoryId");
    localStorage.removeItem("date");
    localStorage.removeItem("mainService");
    localStorage.removeItem("moreDiscover");
    localStorage.removeItem("notes");
    localStorage.removeItem("selectedAddon");
    localStorage.removeItem("selectedService");
    localStorage.removeItem("selectedTime");
    localStorage.removeItem("serviceDetails");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("timeSlot");
    localStorage.removeItem("tabFlag");

    const storedAddress = localStorage.getItem("address");
    const booking_id = localStorage.getItem("bookingId");
    const completeBooking = localStorage.getItem("completeBooking");

    setTab(localStorage.getItem("tabFlag"));

    if (booking_id) {
      localStorage.removeItem("bookingId");
    }
    if (completeBooking) {
      localStorage.removeItem("completeBooking");
    }

    if (storedAddress) {
      setAddress(storedAddress);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    const businessData = JSON.parse(localStorage.getItem("businessData"));
    if (!businessId) {
      navigate("/404_error");
    } else if (!businessData) {
      getBusinessData();
    } else {
      setData(businessData);
    }

  }, []);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&zoom=18`
              );
              const data = await response.json();

              const locationData = {
                lat: latitude.toFixed(6),
                lng: longitude.toFixed(6),
              };

              localStorage.setItem("latlon", JSON.stringify(locationData));

              setCurrentAddress(data.display_name);
              console.log("location >>", data);
            } catch (error) {
              console.error("Error fetching address: ", error);
            }
          },
          (error) => {
            console.error("Error fetching location: ", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  console.log("address >>", currentAddress)

  useEffect(() => {
    if (singleService?.addon_services) {
      setChecked(new Array(singleService.addon_services.length).fill(false));
    }
  }, [singleService]);

  const toggleExpanded = () => {
    setReadFlag(!readFlag);
  };

  const convertToMinutes = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };

  const getBusinessData = () => {
    let params = {
      business_id: businessId,
    };
    setLoader(true);

    getBussiness(params)
      .then((res) => {
        const status = res.data.status;

        if (status === 1) {
          setData(res.data.data);
          localStorage.setItem("businessData", JSON.stringify(res.data.data));
          setLoader(false);
        } else {
          setLoader(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        const errs = err.response?.data;
        const status = err.response?.status;
        if (status == 500) {
          navigate("/no_data_found");
        }
        if (status === 422) {
          toast.error(errs?.errors[0]?.msg);
        } else {
          toast.error(errs?.Message || "An error occurred.");
        }
        setLoader(false);
      });
  };

  const handleAddressChange = async (value) => {
    setAddress(value);
    setIsOpen(false);

    if (value) {
      const placeId = value.value.place_id;

      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressComponents = place.address_components;
          const postalCodeComponent = addressComponents.find((component) =>
            component.types.includes("postal_code")
          );

          let obj = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          console.log("lat >>", obj)

          localStorage.setItem("latlon", JSON.stringify(obj));

          if (postalCodeComponent) {
            console.log(
              "postalCodeComponent.long_name ???",
              postalCodeComponent.long_name
            );
          } else {
            console.log("Postal code not found");
          }
        } else {
          console.error("Error fetching place details:", status);
        }
      });
    }
  };

  const CustomOption = (props) => (
    <components.Option {...props}>
      <div className="flex items-center">
        <img
          src={distance1}
          alt="location icon"
          style={{ width: "1rem", marginRight: "10px" }}
        />
        {props.data.label}
      </div>
    </components.Option>
  );

  const formatDuration = (duration) => {
    if (!duration || typeof duration !== "string") {
      return "N/A";
    }
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    let result = [];

    if (hours > 0) {
      result.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    }
    result.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    return result.join(" ");
  };

  const handleTabClick = (tab) => {
    localStorage.setItem("tabFlag", tab);
    setTab(localStorage.getItem("tabFlag"));
  };

  const handlePriceData = (item) => {
    localStorage.setItem(
      "address",
      typeof address === "object"
        ? address?.label
        : address || currentAddress || "Fetching address..."
    );
    localStorage.setItem("serviceId", item.id);
    localStorage.setItem("categoryId", item.category_id);

    const pricingDetails = {
      mainPrice: Number(item.price),
      addonPrice: 0,
      totalPrice: Number(item.price),
      totalService: 1,
      totalDuration: convertToMinutes(item.duration),
      totalAddonTime: 0,
    };
    localStorage.setItem("serviceDetails", JSON.stringify(pricingDetails));

    navigate(`/service/${item.id}/${item.category_id}`);
  };

  return (
    <div>
      <div className="mb-2">
        <div>
          <div className="sticky top-0 z-10 bg-white ">
            <div className="border-b pb-4 sm:w-[30rem] sm:mx-auto">
              <div className="container mx-auto ">
                <div className="px-4 py-3 bg-[#E7E4F7] rounded-2xl flex justify-between items-center">
                  <div className="flex items-center gap-x-2 sm:w-1/2">
                    <img src={logo} alt="Beauty Butler" className="w-12 h-12" />
                    <div>
                      <p className="sm:text-sm text-xs font-normal heading-font">
                        Beauty Butler
                      </p>
                      <p className="sm:text-xs text-[10px] text-[#667085] font-normal">
                        Beauty treatments direct to your doorstep
                      </p>
                    </div>
                  </div>

                  <a
                    className="bg-[#8877D8] text-white rounded-lg py-2 sm:px-4 sm:w-auto w-44 font-medium text-xs text-center ms-2"
                    href={`${isIOS
                      ? "https://apps.apple.com/us/app/beauty-butler-app/id6608976314"
                      : "https://play.google.com/store/apps/details?id=com.beautybutler.use"
                      }`}
                    target="_blank"
                  >
                    Download App
                  </a>
                </div>
              </div>
            </div>
          </div>

          {loader ? (
            <SkeletonTheme
              highlightColor="#ffffff30"
              borderRadius="0.5rem"
              duration={1.5}
            >
              <div className="py-4 container mx-auto bg-white z-10">
                <Skeleton height={30} />
              </div>
              <div className="container mx-auto">
                <Skeleton height={200} />
                <Skeleton height={50} className="mt-10" />
                <Skeleton height={30} className="mt-5" />
                <Skeleton height={50} className="mt-4 mb-5" />
                <Skeleton height={500} className="mb-5" />
              </div>
            </SkeletonTheme>
          ) : data.length === 0 ? (
            <div className="container mx-auto">
              <p className="text-lg mt-3 text-[#8c8dab] font-medium">
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              <div
                className="flex items-center gap-x-1.5 py-4 cursor-pointer container mx-auto bg-white z-10"
                onClick={() => setIsOpen(true)}
              >
                <img src={distance} alt="location" className="w-6 h-6" />
                <p
                  className="sm:text-sm text-xs text-[#1D2939] font-normal truncate w-10/12 align-middle"
                  onClick={() => setIsOpen(true)}
                >
                  {address?.label ||
                    address ||
                    currentAddress ||
                    "Fetching address..."}
                </p>
                <FiChevronDown className="text-[#667085]" />
              </div>
              <div className="sm:w-[30rem] sm:mx-auto">
                <div>
                  <img
                    src={data.cover_image}
                    className="h-52 w-full object-cover"
                    alt="cover Image"
                  />
                </div>
                <div className="relative -mt-9 mx-4">
                  <img
                    src={data.business_image}
                    alt="business logo"
                    className="w-[70px] h-[70px] mb-3 rounded-full border-2 border-white  object-cover"
                  />
                </div>
              </div>
              <div className="container mx-auto ">
                <p className="sm:text-xl text-lg font-medium pb-2 heading-font">
                  {data.business_name}
                </p>
                <div className="mb-4">
                  <p className="text-[#667085] text-xs sm:text-sm">
                    {readFlag ? data.bio : `${data?.bio?.slice(0, limit)}`}
                    {data.bio?.length > limit && (
                      <button
                        onClick={toggleExpanded}
                        className="text-[#8877D8] font-medium"
                      >
                        {readFlag ? "View Less" : "... View More"}
                      </button>
                    )}
                  </p>
                </div>

                <div className="flex items-center sm:gap-x-3 gap-x-1.5 mb-4">
                  <div className="flex items-center bg-[#F0EDFF] py-1.5 px-2 rounded">
                    <p className="text-[#8877D8] font-medium sm:text-xs text-[10px] heading-font ">
                      {data.business_tag?.tagname}
                    </p>
                  </div>
                  <img src={line} className="h-3 mt-0.5" alt="" />
                  <div className="flex items-center gap-x-1  text-[#667085]">
                    <FaStar className="w-3.5 h-3.5" />
                    <p className="sm:text-sm text-xs text-[#667085] flex font-normal align-middle">
                      <p>
                        {data.average_review_stars}
                      </p>
                      <p className="ms-1">
                        ({data.review?.length})
                      </p>
                    </p>
                  </div>
                  <img src={line} className="h-3 mt-0.5" alt="" />
                  <div className="flex items-center gap-x-1 text-gray-600">
                    <img src={distance1} className="w-4 h-4" alt="location" />
                    <p className="sm:text-sm text-xs text-[#475467] font-normal align-middle">
                      {data.business_address}
                    </p>
                  </div>
                </div>
                <div className="mt-7">
                  <div className="col-span-12 flex items-center bg-[#EAECF0] p-1 rounded-lg">
                    <button
                      role="tab"
                      type="button"
                      onClick={() => {
                        handleTabClick(0);
                      }}
                      className={`col-span-4 w-full sm:text-[0.813rem] text-xs py-2.5 px-2.5 h-full rounded-lg outline-none rounded-full transition-all duration-300 ${!tabFlag || tab == 0
                        ? "bg-white shadow-md font-medium"
                        : "bg-transparent"
                        }`}
                    >
                      Services
                    </button>

                    {tab == 2 && (
                      <div className="h-4 text-[#8E8E93] my-auto border border-[#8E8E9340]"></div>
                    )}
                    <button
                      role="tab"
                      type="button"
                      onClick={() => {
                        handleTabClick(1);
                      }}
                      className={`col-span-4 w-full sm:text-[0.813rem] text-xs py-2.5 px-2.5 h-full rounded-lg outline-none rounded-full transition-all duration-300 ${tab == 1
                        ? "bg-white shadow-md font-medium"
                        : "bg-transparent"
                        }`}
                    >
                      Pictures
                    </button>
                    {tab == 0 && (
                      <div className="h-4 text-[#8E8E93] my-auto border border-[#8E8E9340]"></div>
                    )}
                    <button
                      role="tab"
                      type="button"
                      onClick={() => {
                        handleTabClick(2);
                      }}
                      className={`col-span-4 w-full text-xs sm:text-[0.813rem] py-2.5 px-2.5 h-full rounded-lg outline-none rounded-full transition-all duration-300  ${tab == 2
                        ? "bg-white shadow-md font-medium"
                        : "bg-transparent"
                        }`}
                    >
                      Reviews
                    </button>
                  </div>
                </div>

                <div className="mb-10">
                  {tab == 1 ? (
                    <div className="mt-4">
                      <div className="grid grid-cols-12 gap-1.5">
                        {data.portfolio_images?.map((item, index) => (
                          <div className="col-span-4 sm:h-36 h-28" key={index}>
                            {isLoading ? (
                              <Skeleton className="h-full w-full" />
                            ) : (
                              <img
                                key={index}
                                src={item.portfolio_image}
                                className="rounded-lg object-cover h-full w-full cursor-pointer"
                                alt="portfolio_image"
                                onClick={() => {
                                  setIsOpen1(true);
                                  setImg(item.portfolio_image);
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : tab == 2 ? (
                    <div>
                      <div className="flex items-center container mt-4">
                        <p>5</p>
                        <div className="w-full ms-4 me-10">
                          <Progress completed={data.five_star_reviews * 10} />
                        </div>
                        <p>{data.five_star_reviews}</p>
                      </div>
                      <div className="flex items-center container mt-4">
                        <p>4</p>
                        <div className="w-full ms-4 me-10">
                          <Progress completed={data.four_star_reviews * 10} />
                        </div>
                        <p>{data.four_star_reviews}</p>
                      </div>
                      <div className="flex items-center container mt-4">
                        <p>3</p>
                        <div className="w-full ms-4 me-10">
                          <Progress completed={data.three_star_reviews * 10} />
                        </div>
                        <p>{data.three_star_reviews}</p>
                      </div>
                      <div className="flex items-center container mt-4">
                        <p>2</p>
                        <div className="w-full ms-4 me-10">
                          <Progress completed={data.two_star_reviews * 10} />
                        </div>
                        <p>{data.two_star_reviews}</p>
                      </div>
                      <div className="flex items-center container mt-4">
                        <p>1</p>
                        <div className="w-full ms-4 me-10">
                          <Progress completed={data.one_star_reviews * 10} />
                        </div>
                        <p>{data.one_star_reviews}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="py-4">
                      {data.business_service?.map((item, index) => (
                        <div
                          className="py-4 border-b last:border-b-0 cursor-pointer"
                          key={index}
                          onClick={() => handlePriceData(item)}
                        >
                          <div className="flex items-center justify-between sm:gap-x-3.5 gap-x-2">
                            <div className="sm:text-sm text-xs font-normal">
                              <p className="text-[#1D2939]">
                                {item.service_name}
                              </p>
                              <p className="py-2 text-[#667085] lineclamp">
                                {item.description}
                              </p>

                              <div className="flex items-center gap-x-2 sm:text-sm text-xs mt-1">
                                <p className="text-[#667085] ">
                                  {formatDuration(item.duration)}
                                </p>
                                <img src={line} alt="" className="h-5" />
                                <p className="text-[#667085]">
                                  From ₦{Number(item?.price).toLocaleString()}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <div className="w-5 h-5 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer">
                                <FiChevronRight
                                  size={13}
                                  className="text-[#1D2939]"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
        size="lg"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 container mx-5">
          <Dialog.Panel className="max-w-lg border bg-white rounded-xl shadow-lg">
            <Dialog.Description>
              <div className="flex justify-end items-center">
                <div className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-between">
                  <IoClose
                    className="cursor-pointer w-7 size-6 text-[#667085]"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  />
                </div>
              </div>

              <div className="pb-5 sm:px-5 px-2">
                <p className="text-center font-medium sm:text-xl text-lg pb-4 heading-font">
                  Address
                </p>
                <div className="w-full relative flex items-center py-2 border border-gray-300 rounded-[14px] my-2 pe-4">
                  <div className="w-full flex items-center">
                    <img
                      src={distance}
                      alt="location icon"
                      className="ms-3 w-6"
                    />
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyDuqKaM8WQIZNWbdu2VAVaYrr2I7ZjSpOM"
                      selectProps={{
                        value: address,
                        onChange: handleAddressChange,
                        placeholder: "Enter a new address",
                        components: {
                          Option: CustomOption,
                        },
                        styles: {
                          control: (provided) => ({
                            ...provided,
                            width: "100%",
                            border: "none",
                            boxShadow: "none",
                          }),
                          input: (provided) => ({
                            ...provided,
                            width: "100%",
                            border: "none",
                            boxShadow: "none",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: "calc(100% + 2.7rem)",
                            marginLeft: "-2rem",
                            boxShadow: "none",
                            border: "1px solid rgb(209 213 219)",
                          }),
                          indicatorsContainer: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="py-5 border-b">
                  <p
                    className="font-medium sm:sm:text-sm text-xs text-xs pb-1"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Use your current location
                  </p>
                  <p
                    className="text-gray-600 font-normal sm:sm:text-sm text-xs text-xs cursor-pointer"
                    onClick={() => {
                      setAddress(currentAddress);
                      setIsOpen(false);
                    }}
                  >
                    {currentAddress}
                  </p>
                </div>
              </div>
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog
        open={isOpen1}
        onClose={() => setIsOpen1(false)}
        className="relative z-50"
        size="lg"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 container mx-5">
          <Dialog.Panel className="max-w-lg border bg-white rounded-xl shadow-lg">
            <Dialog.Description>
              <div className="flex justify-end items-center">
                <div className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-between">
                  <IoClose
                    className="cursor-pointer w-7 size-6 text-[#667085]"
                    onClick={() => {
                      setIsOpen1(false);
                    }}
                  />
                </div>
              </div>

              <div className="md:h-96 h-80">
                <div className="w-full md:h-96 h-80 flex items-center pt-2 md:py-5">
                  <img
                    src={img}
                    alt="location icon"
                    className="object-cover h-full w-full"
                  />
                </div>
              </div>
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
