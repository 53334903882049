import logo from "./logo.svg";
import "./App.css";
import RoutData from "../src/router/router";
import { Toaster } from "react-hot-toast";
import { TimeProvider } from "../src/Components/TimeContext/TimeContext";
import { DateProvider } from "../src/Components/DateProvider/DateProvider";
function App() {
  return (
    <>
      <TimeProvider>
        <DateProvider>
          <RoutData />
        </DateProvider>
      </TimeProvider>

      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "text-[14px]",
          style: {
            background: "#313049",
            color: "#FFFFFF",
          },
        }}
      />
    </>
  );
}

export default App;
