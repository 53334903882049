import axios from "axios";
import {
  ADD_BOOKING,
  COMPLETE_BOOKING_API,
  FINAL_STEP,
  GET_BOOKING_DETAILS,
  GET_BUSINESS,
  GET_BUTLER,
  MORE_TO_DISCOVER,
  SEND_OTP,
  TIme_SLOT,
  VERIFY_OTP,
} from "./Api";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const headers = {
  "Content-Type": "application/json",
};

const handleFinalStep = (params) => {
  return axios.put(FINAL_STEP, params, { headers: headers });
};

const getBussiness = (params) => {
  return axios.get(GET_BUSINESS, { headers: headers, params: params });
};

const sendOtp = (params) => {
  return axios.post(SEND_OTP, params, { headers: headers });
};

const verifyOtp = (params) => {
  return axios.post(VERIFY_OTP, params, { headers: headers });
};

const getButler = (params) => {
  return axios.get(GET_BUTLER, {
    headers: headers,
    params: params,
  });
};

const getMoreToDiscover = (params) => {
  return axios.get(MORE_TO_DISCOVER, {
    headers: headers,
    params: params,
  });
};

const getTimeSlot = (params) => {
  return axios.post(TIme_SLOT, params, { headers: headers });
};

export const completePaymetApi = (params) => {
  return axios.post(COMPLETE_BOOKING_API, params, { headers: headers });
};

export const getBooking = (params) => {
  return axios.post(GET_BOOKING_DETAILS, params, { headers: headers });
};

export const addBooking = (params) => {
  return axios.post(ADD_BOOKING, params, { headers: headers });
};

export const bookingDetails = (params) => {
  return axios.get(ADD_BOOKING, { headers: headers, params: params });
};

export {
  getBussiness,
  sendOtp,
  verifyOtp,
  handleFinalStep,
  getButler,
  getMoreToDiscover,
  getTimeSlot,
};
