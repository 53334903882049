import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { sendOtp } from "../../Services/ApiService";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";

export default function Login() {

  const navigate = useNavigate();

  const business = JSON.parse(localStorage.getItem("businessData"));
  const token = localStorage.getItem("token");

  const [otp, setOtp] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [phoneData, setPhoneData] = useState({
    number: "",
    countryCode: "ng",
    dialCode: "234",
    inputValue: "",
    isValid: false,
  });

  useEffect(() => {
    if (token) {
      navigate(`/?id=${business.id.id}`);
    }
  }, []);

  const handlePhoneChange = (phone, countryCode) => {
    const userInput = phone
      .replace(`+${countryCode.country.dialCode}`, "")
      .trim();

    const parsedPhone = parsePhoneNumberFromString(phone);

    setPhoneData({
      number: phone,
      countryCode: countryCode.country.iso2,
      dialCode: `+${countryCode.country.dialCode}`,
      inputValue: userInput,
      isValid: parsedPhone ? parsedPhone.isValid() : false,
    });
  };

  const handleDone = () => {
    setIsLoading(true);

    let params = {
      country_code: phoneData.dialCode,
      iso_code: phoneData.countryCode,
      phone_number: phoneData.inputValue,
    };

    sendOtp(params)
      .then((res) => {
        const status = res.data.status;
        if (status == 1) {
          setOtp(res.data.otp);
          toast.success(res.data.message);
          navigate("/verification", {
            state: { phone: params, otp: otp },
          });
        } else {
          toast.error(res.data.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          const errs = err.response.data;
          const status = err.response?.status;
          if (status === 422) {
            toast.error(errs?.message);
          } else if (status === 400) {
            toast.error(errs?.message);
          } else {
            toast.error(errs?.message || "An error occurred.");
          }
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="container mx-auto my-10">
      <div className="flex items-center justify-end">
        <p className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-center">
          <IoClose
            className="cursor-pointer size-5"
            onClick={() => navigate(-1 || `/${business.id}`)}
          />
        </p>
      </div>
      <div className="pb-5">
        <div className="mb-8">
          <p className="font-medium text-2xl sm:text-3xl pb-2">
            Log in or sign up
          </p>
          <p className="text-gray-500 sm:text-sm text-xs heading-font">
            Log in or sign up to complete your booking
          </p>
        </div>
        <form>
          <div className="flex items-center gap-x-2 mb-10">
            <PhoneInput
              defaultCountry="ng"
              value={phoneData.number}
              onChange={handlePhoneChange}
              className="block w-full rounded-xl bg-white shadow-sm focus:ring-indigo-500 sm:text-sm text-xs"
              required
            />
          </div>

          <button
            type="button"
            className={`rounded-2xl py-4 w-full font-medium sm:text-lg flex items-center justify-center ${phoneData.isValid
              ? "bg-[#8877D8] text-white"
              : "bg-gray-300 text-gray-400 cursor-not-allowed"
              }`}
            onClick={handleDone}
            disabled={!phoneData.isValid}
          >
            {isLoading ? (
              <ThreeDots height="28" width="50" color="#fff" visible={true} />
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
