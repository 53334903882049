import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import "react-international-phone/style.css";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { sendOtp, verifyOtp } from "../../Services/ApiService";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { ThreeDots } from "react-loader-spinner";

export default function Varification() {
  const location = useLocation();
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [remainingTime, setRemainingTime] = useState(60);
  const [deviceId, setDeviceId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let number;
  let otpValue;
  try {
    const { phone, otp } = location.state;
    number = phone;
    otpValue = otp;
  } catch (error) {
    console.log("err", error);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [remainingTime]);

  useEffect(() => {
    const generatedDeviceId = uuidv4();
    setDeviceId(generatedDeviceId);
  }, []);

  const handleverification = () => {
    let params = {
      country_code: number?.country_code,
      iso_code: number?.iso_code,
      phone_number: number?.phone_number,
      otp: otp,
      device_id: deviceId,
      device_token: "abcd",
      device_type: "Web",
    };
    setIsLoading(true);

    verifyOtp(params)
      .then((res) => {
        const status = res.data.status;
        const data = res.data;
        if (status == 1) {
          toast.success(res.data.message);
          localStorage.setItem("token", data.token);
          localStorage.setItem("userData", JSON.stringify(data.user));
          if (data.user?.fullname) {
            localStorage.removeItem("butlerFlag");
            navigate("/review");
          } else {
            navigate("/personal_info");
          }
        } else {
          toast.error(res.data.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          const errs = err.response.data;
          const status = err.response?.status;

          if (status === 400) {
            toast.error(errs?.message);
          } else {
            toast.error(errs?.Message || "An error occurred.");
          }
        }
        setIsLoading(false);
      });
  };

  const handleSendOtp = () => {
    let params = {
      country_code: number?.country_code,
      iso_code: number?.iso_code,
      phone_number: number?.phone_number,
    };

    sendOtp(params)
      .then((res) => {
        const status = res.data.status;
        if (status == 1) {
          setRemainingTime(60);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response) {
          localStorage.removeItem("remainingTime");
          const errs = err.response.data;
          const status = err.response?.status;

          if (status === 422) {
            toast.error(errs?.message);
          } else if (status === 400) {
            toast.error(errs?.message);
          } else {
            toast.error(errs?.message || "An error occurred.");
          }
        }
      });
  };

  return (
    <div className="container mx-auto my-10">
      <div className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-center mb-6">
        <FaChevronLeft
          className="cursor-pointer w-4 text-gray-500"
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="pb-5">
        <div className="mb-8">
          <p className="font-medium sm:text-3xl text-2xl pb-2 heading-font">
            Verify your phone number
          </p>
          <p className=" text-gray-500 sm:text-sm text-xs">
            Please enter the verification code sent to {number?.country_code}
            {number?.phone_number}
          </p>
        </div>
        <div className="flex justify-center mb-4">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType="tel"
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  backgroundColor: props.value
                    ? "rgb(255, 255, 255)"
                    : "rgb(255, 255, 255)",
                  border: props.value
                    ? "1.5px solid rgb(136, 119, 216)"
                    : "1.5px solid rgba(208, 213, 221, 1)",
                  outline: props.value
                    ? "solid 4px rgba(136, 119, 216, 0.125) "
                    : "none",
                }}
                className="handleclosj custom-outline"
              />
            )}
          />
        </div>
        <div className="mt-0.5 text-center font-medium sm:text-sm text-xs">
          {remainingTime > 0 ? (
            <p className="text-gray-500 "> Resend code in 00:{remainingTime}</p>
          ) : (
            <p
              className="text-[#8877D8] cursor-pointer"
              onClick={handleSendOtp}
            >
              {" "}
              Resend code?
            </p>
          )}
        </div>

        <button
          className={`bg-[#8877D8] rounded-2xl py-4 w-full font-medium sm:text-lg mt-10 flex items-center justify-center ${otp.length === 4
            ? "bg-[#8877D8] text-white"
            : "bg-gray-300 text-gray-400 cursor-not-allowed"
            }`}
          onClick={handleverification}
          disabled={otp.length < 4}
        >
          {isLoading ? (
            <ThreeDots height="28" width="50" color="#fff" visible={true} />
          ) : (
            "Verify"
          )}
        </button>

        <p
          className="pt-4 text-gray-500 font-medium sm:text-sm text-xs text-center"
          onClick={() => navigate("/login")}
        >
          Not your phone number?{" "}
          <span className="text-[#8877D8] cursor-pointer">
            Change phone number
          </span>
        </p>
      </div>
    </div>
  );
}
