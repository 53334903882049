import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import line from "../../assets/images/Line.png";
import walk from "../../assets/images/directions_walk.png";
import like from "../../assets/images/like.png";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getBussiness, getMoreToDiscover } from "../../Services/ApiService";
import toast from "react-hot-toast";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ServiceDetails() {
  const navigate = useNavigate();
  const { id, cid } = useParams();

  const [readFlag, setReadFlag] = useState(false);
  const [loader, setLoader] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedServiceCount, setSelectedServiceCount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalAddonTime, setTotalAddonTime] = useState(0);
  const [totalMainPrice, setTotalMainPrice] = useState(0);
  const [totalAddonPrice, setTotalAddonPrice] = useState(0);

  const [selectedService, setSelectedService] = useState({});
  const [singleService, setSingleService] = useState({});

  const [moreDiscover, setMoreDiscover] = useState([]);
  const [checked, setChecked] = useState([]);
  const selectedAddon = JSON.parse(localStorage.getItem("selectedAddon")) || [];

  const limit = 150;

  const settings = {
    arrows: false,
    speed: 500,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const settings1 = {
    arrows: false,
    speed: 500,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    centerMode: true,
  };
  const businessData = JSON.parse(localStorage.getItem("businessData"));

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedServiceDetails = localStorage.getItem("serviceDetails");
    const storedMain = localStorage.getItem("mainService");
    const storedSelected = localStorage.getItem("selectedService");
    const storedDiscover = localStorage.getItem("moreDiscover");


    if (storedServiceDetails) {
      const { mainPrice, addonPrice, totalPrice, totalDuration, totalService } =
        JSON.parse(storedServiceDetails);
      setTotalMainPrice(mainPrice);
      setTotalAddonPrice(addonPrice);
      setTotalPrice(totalPrice);
      setSelectedServiceCount(totalService);
      setTotalTime(totalDuration);
    }
    if (storedMain && storedSelected && storedDiscover) {
      setSingleService(JSON.parse(localStorage.getItem("mainService")));
      setSelectedService(JSON.parse(localStorage.getItem("selectedService")));
      setMoreDiscover(JSON.parse(localStorage.getItem("moreDiscover")));
    }
  }, []);

  useEffect(() => {
    getServiceData();
    getDiscoverData();
  }, [id, cid]);

  const isItemSelected = (id) => {
    return selectedAddon.some((addon) => addon.id === id);
  };

  const formatDuration = (duration) => {
    if (!duration || typeof duration !== "string") {
      return "N/A";
    }
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    let result = [];

    result.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    result.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    return result.join(" ");
  };

  const toggleExpanded = () => {
    setReadFlag(!readFlag);
  };

  const formatTotalTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${hours} hour${hours > 1 ? "s" : ""}, ${minutes} minute${minutes > 1 ? "s" : ""
      }`;
  };

  const convertToMinutes = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };

  const handleCheckboxClick1 = (item) => {
    const newChecked = [...checked];
    newChecked[item.id] = !newChecked[item.id];
    setChecked(newChecked);

    const itemDuration = convertToMinutes(item.duration);

    const newAddonPrice = newChecked[item.id]
      ? totalAddonPrice + item.price
      : totalAddonPrice - item.price;

    const newTotalPrice = totalMainPrice + newAddonPrice;

    const newSelectedServiceCount = newChecked[item.id]
      ? selectedServiceCount + 1
      : selectedServiceCount - 1;

    const newAddonTime = newChecked[item.id]
      ? totalAddonTime + itemDuration
      : totalAddonTime - itemDuration;

    const newTotalTimeInMinutes = newChecked[item.id]
      ? totalTime + itemDuration
      : totalTime - itemDuration;

    setTotalAddonPrice(newAddonPrice);
    setTotalPrice(newTotalPrice);
    setSelectedServiceCount(newSelectedServiceCount);
    setTotalTime(newTotalTimeInMinutes);
    setTotalAddonTime(newAddonTime);
  };

  const handleServiceBack = () => {
    localStorage.removeItem("mainService");
    localStorage.removeItem("selectedService");
    localStorage.removeItem("moreDiscover");
    localStorage.removeItem("selectedAddon");
    navigate(-1);
  };

  const getServiceData = () => {
    let params = {
      business_id: businessData.id,
      service_id: Number(id),
    };
    setLoader(true);

    getBussiness(params)
      .then((res) => {
        const status = res.data.status;
        const services = res?.data?.data;
        if (status == 1) {
          setSelectedService(res.data.data);
          setSingleService(services?.business_service[0]);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        const errs = err.response?.data;
        const status = err.response?.status;
        if (status == 422) {
          toast.error(errs?.errors[0]?.msg);
        } else {
          toast.error(errs?.Message || "An error occurred.");
        }
        setLoader(false);
      });
  };

  const getDiscoverData = () => {
    let params1 = {
      category_id: cid,
      service_id: id,
    };

    getMoreToDiscover(params1)
      .then((res) => {
        const status = res.data.status;
        const data = res.data.data;
        if (status == 1) {
          setMoreDiscover(data.slice(0, 3));
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        const errs = err.response?.data;
        const status = err.response?.status;
        if (status == 500) {
          toast.error(errs?.message);
        } else {
          toast.error(errs?.Message || "An error occurred.");
        }
      });
  };

  const handleBookNow = () => {
    const storedServiceDetails = JSON.parse(
      localStorage.getItem("serviceDetails")
    );
    const service_id = localStorage.getItem("serviceId");

    const updatedValues = {
      ...storedServiceDetails,
      addonPrice: totalAddonPrice,
      totalPrice: totalPrice,
      totalService: selectedServiceCount,
      totalDuration: totalTime,
      totalAddonTime: totalAddonTime,
    };

    localStorage.setItem("serviceDetails", JSON.stringify(updatedValues));

    localStorage.setItem("mainService", JSON.stringify(singleService));
    localStorage.setItem("selectedService", JSON.stringify(selectedService));
    localStorage.setItem("moreDiscover", JSON.stringify(moreDiscover));
    navigate("/butler");
  };

  return (
    <div>
      <div className="sm:w-[30rem] sm:mx-auto">
        {loader ? (
          <SkeletonTheme
            highlightColor="#ffffff30"
            borderRadius="0.5rem"
            duration={5}
          >
            <div>
              <div className="sm:w-[30rem] sm:mx-auto">
                <Skeleton height={280} />
              </div>
              <div className="container mx-auto">
                <Skeleton height={20} className="mt-5" />
                <Skeleton height={40} className="mt-2" />
                <Skeleton height={20} className="mt-2" />
              </div>
            </div>
          </SkeletonTheme>
        ) : (
          singleService.length != 0 && (
            <>
              <div
                className="flex items-center justify-center cursor-pointer rounded-full w-7 h-7 bg-gray-100 mt-10 ms-5 absolute z-10"
                onClick={() => handleServiceBack()}
              >
                <FiChevronLeft className="w-4" />
              </div>
              <div>
                {singleService?.service_images?.length > 1 ? (
                  <Slider {...settings}>
                    {singleService.service_images.map((item, index) => (
                      <div key={index}>
                        <img
                          src={item.service_image}
                          className="object-cover w-full h-72"
                          alt={item.service_name}
                        />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  singleService?.service_images?.length === 1 && (
                    <div>
                      <img
                        src={singleService.service_images[0].service_image}
                        className="object-cover w-full h-72"
                        alt={singleService.service_name}
                      />
                    </div>
                  )
                )}
              </div>
              <div className="container mx-auto">
                <div className="mt-5">
                  <p className="text-[#1D2939] font-medium heading-font sm:text-xl text-lg">
                    {singleService.service_name}
                  </p>
                  <p className="mt-2 text-[#667085] text-xs sm:text-sm">
                    {readFlag
                      ? singleService.description
                      : `${singleService.description?.slice(0, limit)}`}
                    {singleService.description?.length > limit && (
                      <button
                        onClick={toggleExpanded}
                        className="text-[#8877D8] font-medium"
                      >
                        {readFlag ? "View Less" : "... View More"}
                      </button>
                    )}
                  </p>

                  <div className="mt-2 flex items-center gap-x-2 sm:text-sm text-xs pb-5">
                    <p className="text-[#667085] ">
                      {formatDuration(singleService?.duration)}
                    </p>
                    <img src={line} alt="" className="h-5" />
                    <p className="text-[#667085]">
                      ₦{Number(singleService?.price).toLocaleString()}
                    </p>
                    <img src={line} alt="" className="h-5" />
                    <p className="text-[#667085] flex items-center">
                      <span>
                        <img src={walk} alt="" className="h-3.5" />
                      </span>
                      {selectedService?.duration}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </div>

      <div className="container mx-auto mb-52">
        {/* Addon Services */}

        {singleService?.addon_services?.length?.length != 0 && loader ? (
          <SkeletonTheme
            highlightColor="#ffffff30"
            borderRadius="0.5rem"
            duration={5}
          >
            <div className="container mx-auto">
              <Skeleton height={40} className="mt-5" />
            </div>
          </SkeletonTheme>
        ) : (
          <>
            {singleService?.addon_services?.length != 0 && (
              <div className="pb-5">
                <div className="mt-7">
                  <p className="heading-font font-medium sm:text-lg text-base">
                    Add-ons
                  </p>

                  {singleService?.addon_services?.map((item, index) => (
                    <div
                      className="py-3 border-b last:border-b-0 mt-3"
                      key={index}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="sm:text-sm text-xs font-normal ">
                          <p className="text-[#1D2939]">
                            {item.service_name}
                          </p>
                          <p className="text-[#667085] pt-1">
                            {formatDuration(item?.duration)}
                          </p>
                        </div>
                        <div className="flex items-center gap-x-2">
                          <p className="sm:text-sm text-xs font-medium text-[#667085]">
                            ₦{Number(item?.price).toLocaleString()}
                          </p>
                          <input
                            type="checkbox"
                            className="w-6 h-6 custom-checkbox rounded-lg"
                            // checked={checked[item.id] || false}
                            checked={isItemSelected(item.id)}
                            onChange={(e) => handleCheckboxClick1(item)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        {/* More For You */}
        {moreDiscover?.length != 0 && loader ? (
          <SkeletonTheme
            highlightColor="#ffffff30"
            borderRadius="0.5rem"
            duration={5}
          >
            <div className="container mx-auto">
              <Skeleton height={300} className="mt-4" />
              <Skeleton height={40} className="mt-4" />
              <Skeleton height={50} className="mt-4" />
            </div>
          </SkeletonTheme>
        ) : (
          moreDiscover.length != 0 && (
            <div className="border-t">
              <div
                className="flex items-center gap-x-1.5 mt-7 cursor-pointer"
                onClick={() => navigate("/more_to_discover")}
              >
                <p className="heading-font font-medium sm:text-lg text-base text-[#1D2939]">
                  More for you to discover
                </p>
                <div className="flex items-center justify-center cursor-pointer rounded-full w-5 h-5 bg-gray-100">
                  <FiChevronRight className="w-4" />
                </div>
              </div>
              <div className="mt-4">
                {moreDiscover.length > 1 ? (
                  <Slider {...settings1}>
                    {moreDiscover.map((item, index) => (
                      <div
                        key={index}
                        className={`${index != 0 ? "pl-4" : ""}  `}
                      >
                        <img
                          src={
                            item?.business_service[0]?.service_images[0]
                              ?.service_image
                          }
                          className="object-cover w-full sm:h-[25rem] h-[350px] rounded-3xl cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/service/${item.id}/${item.business_service[index]?.category_id}`
                            )
                          }
                          alt={item?.business_service?.service_name}
                        />{" "}
                        <div className="flex items-center justify-end -mt-10 me-2 relative cursor-pointer">
                          <img
                            src={like}
                            alt=""
                            className="h-8 w-8"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate("/login");
                            }}
                          />
                        </div>
                        <div className="mt-5">
                          <p className="text-base sm:text-lg font-medium text-[#1D2939]">
                            {item.business_name}
                          </p>
                          <p className="pt-2 sm:text-base text-sm text-[#667085]">
                            {item?.business_service[0]?.service_name}
                          </p>
                          <div className="flex items-center sm:gap-x-2 gap-x-1">
                            <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                              <FaStar className="w-3.5 h-3.5" />
                              <p className="sm:text-base text-sm text-[#667085]">
                                {item?.business_service[0]?.average_rating.toFixed(
                                  2
                                )}{" "}
                                ({item?.review?.length})
                              </p>
                              <img src={line} className="h-4" alt="" />
                            </div>
                            <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                              <img src={walk} alt="" className="h-3.5" />
                              <p className="sm:text-base text-sm text-[#667085]">
                                {item?.duration || "0 min"}
                              </p>
                              <img src={line} className="h-4" alt="" />
                            </div>
                            <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                              <p className="sm:text-base text-sm text-[#667085]">
                                from ₦
                                {Number(
                                  item?.business_service[0]?.price
                                ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  moreDiscover.map((item, index) => (
                    <div
                      key={index}
                      className={`${index != 0 ? "pl-4" : ""}`}
                    >
                      <img
                        src={
                          item?.business_service[0]?.service_images[0]
                            ?.service_image
                        }
                        className="object-cover w-full sm:h-[25rem] h-[350px] rounded-3xl cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/service/${item.id}/${item.business_service[index]?.category_id}`
                          )
                        }
                        alt={item?.business_service?.service_name}
                      />{" "}
                      <div className="flex items-center justify-end -mt-10 me-2 relative cursor-pointer">
                        <img
                          src={like}
                          alt=""
                          className="h-8 w-8"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate("/login");
                          }}
                        />
                      </div>
                      <div className="mt-5">
                        <p className="text-base sm:text-lg font-medium text-[#1D2939]">
                          {item.business_name}
                        </p>
                        <p className="pt-2 sm:text-base text-sm text-[#667085]">
                          {item?.business_service[0]?.service_name}
                        </p>
                        <div className="flex items-center sm:gap-x-2 gap-x-1">
                          <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                            <FaStar className="w-3.5 h-3.5" />
                            <p className="sm:text-base text-sm text-[#667085]">
                              {item?.business_service[0]?.average_rating.toFixed(
                                2
                              )}{" "}
                              ({item?.review?.length})
                            </p>
                            <img src={line} className="h-4" alt="" />
                          </div>
                          <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                            <img src={walk} alt="" className="h-3.5" />
                            <p className="sm:text-base text-sm text-[#667085]">
                              {item?.duration || "0 min"}
                            </p>
                            <img src={line} className="h-4" alt="" />
                          </div>
                          <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                            <p className="sm:text-base text-sm text-[#667085]">
                              from ₦
                              {Number(
                                item?.business_service[0]?.price
                              ).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          )
        )}
      </div>

      {/* Fixed Content */}
      {loader ? (
        <SkeletonTheme
          highlightColor="#ffffff30"
          borderRadius="0.5rem"
          duration={5}
        >
          <div>
            <div className="container mx-auto">
              <Skeleton height={60} />
              <Skeleton height={60} className=" " />
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div className="sm:w-[30rem] sm:mx-auto mt-5 fixed inset-x-0 bottom-0 bg-white border-t border-t border-[#EAECF0] pt-3">
          <div>
            <div
              className=" flex items-center justify-between container mx-auto  cursor-pointer "
              onClick={() => handleServiceBack()}
            >
              <div className="flex items-center gap-x-3">
                <img
                  src={selectedService.business_image}
                  alt="business logo"
                  className="rounded-full h-10 w-10 object-cover"
                />
                <div className="sm:text-sm text-xs ">
                  <p className="font-medium text-[#1D2939]">
                    {selectedService.business_name}
                  </p>
                  <p className="flex items-center gap-x-1 text-[#667085]">
                    <span>
                      <FaStar className="w-2.5 h-2.5" />
                    </span>
                    {selectedService.average_review_stars} (
                    {selectedService?.review?.length})
                  </p>
                </div>
              </div>
              <div className="w-5 h-5 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer">
                <FiChevronRight size={13} className="text-[#1D2939]" />
              </div>
            </div>
          </div>
          <div className="sm:w-[30rem] sm:mx-auto my-4 border-t border-[#EAECF0] pt-3">
            <div className="flex items-center justify-between container mx-auto ">
              <div>
                <p className="heading-font text-[#1D2939] text-lg font-medium sm:text-base">
                  ₦{Number(totalPrice).toLocaleString()}
                </p>

                <div className="flex items-center gap-x-2">
                  <p className="text-gray-500 text-xs sm:text-sm">
                    {selectedServiceCount}{" "}
                    {selectedServiceCount <= 1 ? "service" : "services"}
                  </p>
                  <img src={line} className="h-3 mt-0.5" alt="" />
                  <p className="text-gray-500 text-xs sm:text-sm">
                    {formatTotalTime(totalTime)}
                  </p>
                </div>
              </div>
              <button
                className="bg-[#8877D8] text-white rounded-xl sm:py-3 sm:px-7 py-3 px-4 font-medium text-center sm:text-lg"
                onClick={() => {
                  handleBookNow();
                }}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
