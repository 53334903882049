import { createContext, useState, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DateContext = createContext();

export const useDate = () => useContext(DateContext);

const formatDateWithOrdinal = (date) => {
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const year = date.getFullYear();

  const suffix = (day) => {
    if (day > 3 && day < 21) return "th"; // catches 11th-19th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${month} ${day}${suffix(day)}, ${year}`;
};

export const DateProvider = ({ children }) => {
  const today = new Date();
  const formattedToday = formatDateWithOrdinal(today);

  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [formatedDate, setFormatedDate] = useState(formattedToday);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    const currentFormattedDate = formatDateWithOrdinal(new Date());
    setDate(currentDate);
    setFormatedDate(currentFormattedDate);
  }, []);

  return (
    <DateContext.Provider
      value={{ date, setDate, formatedDate, setFormatedDate }}
    >
      {children}
    </DateContext.Provider>
  );
};
