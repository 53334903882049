import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import line from "../../assets/images/Line.png";
import walk from "../../assets/images/directions_walk.png";
import like from "../../assets/images/like.png";
import { FiChevronLeft } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getMoreToDiscover } from "../../Services/ApiService";
import toast from "react-hot-toast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isIOS } from "react-device-detect";

export default function MoreToDiscover() {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [moreDiscover, setMoreDiscover] = useState([]);
  const service_id = localStorage.getItem("serviceId");
  const category_id = localStorage.getItem("categoryId");

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const storedLatLon = localStorage.getItem("latlon");
  //   if (storedLatLon) {
  //     obj = JSON.parse(storedLatLon);
  //   }
  //   getDiscoverData(obj);
  // }, []);

  const handleServiceBack = () => {
    navigate(`/service`);
  };

  const getDiscoverData = (obj) => {
    let params1 = {
      category_id: Number(category_id),
      user_Lat: obj.lat,
      user_Long: obj.lng,
      service_id: Number(service_id),
    };

    getMoreToDiscover(params1)
      .then((res) => {
        const status = res.data.status;
        const data = res.data.data;
        if (status == 1) {
          setMoreDiscover(data);
          setLoader(false);
        } else {
          toast.error(data.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        const errs = err.response?.data;
        const status = err.response?.status;
        if (status == 500) {
          toast.error(errs?.message);
          setLoader(false);
        } else {
          toast.error(errs?.Message || "An error occurred.");
          setLoader(false);
        }
      });
  };

  return (
    <div>
      <div className="sticky top-0 z-10 bg-white ">
        <div className="border-b pb-4 sm:w-[30rem] sm:mx-auto">
          <div className="container mx-auto ">
            <div className="px-4 py-3 bg-[#E7E4F7] rounded-2xl flex justify-between items-center">
              <div className="flex items-center gap-x-2 sm:w-1/2">
                <img src={logo} alt="Beauty Butler" className="w-12 h-12" />
                <div>
                  <p className="sm:text-sm text-xs font-normal heading-font">
                    Beauty Butler
                  </p>
                  <p className="sm:text-xs text-[10px] text-[#667085] font-normal">
                    Beauty treatments direct to your doorstep
                  </p>
                </div>
              </div>
              <a
                className="bg-[#8877D8] text-white rounded-lg py-2 sm:px-4 sm:w-auto w-44 font-medium text-xs text-center ms-2"
                href={`${isIOS
                  ? "https://apps.apple.com/app/id6608976314"
                  : "https://play.google.com/store/apps/details?id=com.beautybutler.use"
                  }`}
                target="_blank"
              >
                Download App
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="container mx-auto">
        {moreDiscover?.length != 0 && loader ? (
          <SkeletonTheme
            highlightColor="#ffffff30"
            borderRadius="0.5rem"
            duration={5}
          >
            <div>
              <div className="container mx-auto">
                <Skeleton height={300} className="mt-4" />
                <Skeleton height={40} className="mt-4" />
                <Skeleton height={50} className="mt-4" />
              </div>
            </div>
          </SkeletonTheme>
        ) : (
          moreDiscover.length != 0 && (
            <div className="border-t">
              <div className="flex items-center justify-between gap-x-1.5 mt-7">
                <div
                  className="flex items-center justify-center cursor-pointer rounded-full w-5 h-5 bg-gray-100"
                  onClick={handleServiceBack}
                >
                  <FiChevronLeft className="w-4" />
                </div>
                <p className="heading-font font-medium sm:text-xl text-lg text-[#1D2939]">
                  More for you to discover
                </p>
                <p></p>
              </div>
              <div className="mt-4">
                <>
                  {moreDiscover.length > 0 &&
                    moreDiscover.map((item, index) => (
                      <div
                        key={index}
                        className={`${index != 0 ? "pl-4" : ""}`}
                      >
                        <img
                          src={
                            item?.business_service[0]?.service_images[0]
                              ?.service_image
                          }
                          className="object-cover w-full sm:h-[25rem] h-[350px] rounded-3xl"
                          alt={item?.business_service?.service_name}
                        />{" "}
                        <div className="flex items-center justify-end -mt-10 me-2 relative cursor-pointer">
                          <img
                            src={like}
                            alt=""
                            className="h-8 w-8"
                            onClick={() => navigate("/login")}
                          />
                        </div>
                        <div className="mt-5">
                          <p className="text-base sm:text-lg font-medium text-[#1D2939]">
                            {item.business_name}
                          </p>
                          <p className="pt-2 sm:text-base text-sm text-[#667085]">
                            {item?.business_service[0]?.service_name}
                          </p>
                          <div className="flex items-center sm:gap-x-2 gap-x-1">
                            <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                              <FaStar className="w-3.5 h-3.5" />
                              <p className="sm:text-base text-sm text-[#667085]">
                                {item?.business_service[0]?.average_rating.toFixed(
                                  2
                                )}{" "}
                                ({item?.review?.length})
                              </p>
                              <img src={line} className="h-4" alt="" />
                            </div>
                            <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                              <img src={walk} alt="" className="h-3.5" />
                              <p className="sm:text-base text-sm text-[#667085]">
                                {item?.duration || "0 min"}
                              </p>
                              <img src={line} className="h-4" alt="" />
                            </div>
                            <div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
                              <p className="sm:text-base text-sm text-[#667085]">
                                from ₦
                                {Number(
                                  item?.business_service[0]?.price
                                ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
